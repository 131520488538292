import React from 'react';
import ProductSearchCard from '../product-search-card/product-search-card.component';
import ProductSearchCardMui from '../product-search-card/product-search-card-mui.component';  
import './product-search-grid.styles.css';

const ProductSearchGrid = ({ products, renderActionButton }) => {
  return (
    <div className={`product-search-grid-container`}>
      <div className={`product-search-grid`}>
        {products.map((product, idx) => (
          <ProductSearchCardMui
            key={idx}
            product={product}
            renderActionButton={renderActionButton}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductSearchGrid;
