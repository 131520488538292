import { createPortal } from 'react-dom';
import React,{ useRef, useContext } from 'react';
import CallToAction from '../call-to-action/call-to-action.component';

import { useCtaContext } from '../../context/cta.context';
import { useCommandContext } from '../../context/command.context';
import { useModalContext } from '../../context/modal.context';
import { ProjectContext } from '../../context/project.context';

import './cta-group.styles.css';

const CtaGroup = ({ visible, onCtaClick }) => {
  const ctaRef = useRef(null);
  const { activeCta, setActiveCta } = useCtaContext();
  const { activeCommand, setActiveCommand } = useCommandContext();
  const { activeModal, setActiveModal } = useModalContext();
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);

  const handleClick = (command) => {
    if (!currentProject && command !== 'createProject') {
      return;
    }
    if (!currentProject && command === 'createProject') {
      return;
    }
    setActiveCta('');
    setActiveCommand(command);
    setActiveModal('');
    onCtaClick(true);
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div ref={ctaRef} className="cta-group-container">
      {currentProject && (
      <div className="cta-group">
        <CallToAction
          ctaType="design"
          ctaText="Design my room"
          onClick={handleClick}
        />
        <CallToAction
          ctaType="paint"
          ctaText="Change wall color"
          onClick={handleClick}
        />
        <CallToAction
          ctaType="moodboard"
          ctaText="Create a moodboard"
          onClick={handleClick}
        />
        <CallToAction
            ctaType="smartSearch"
            ctaText="Where/What to buy?"
            onClick={handleClick}
          />
        </div>
      )}
      {!currentProject && (
        <div className="cta-project-create">
          Create a new project on the left or select an existing project to start
        </div>
      )}
    </div>,
    document.getElementById('cta-portal'),
  );
};

export default CtaGroup;
