import React, { createContext, useState, useContext, useCallback } from 'react';

const ImageContext = createContext();

const s3_url = process.env.REACT_APP_S3_URL;
export const DEFAULT_AVATAR = s3_url + '/avatars/generic_avatar.webp';

export const ImageProvider = ({ children }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = useCallback(async (fileOrUrl) => {
    if (fileOrUrl instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage({
          file: fileOrUrl,
          dataUrl: reader.result,
        });
      };
      reader.readAsDataURL(fileOrUrl);
    } else if (typeof fileOrUrl === 'string') {
      try {
        const response = await fetch(fileOrUrl, {
          method: 'GET',
          mode: 'cors',
          credentials: 'omit',
          headers: {
            'Accept': 'image/*'
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadedImage({
            file: blob,
            dataUrl: reader.result,
          });
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error loading image from URL:', error);
      }
    } else {
      console.error('Invalid input type');
    }
  }, []);

  const clearUploadedImage = useCallback(() => {
    setUploadedImage(null);
  }, []);

  return (
    <ImageContext.Provider
      value={{ uploadedImage, handleImageUpload, clearUploadedImage }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = () => {
  const context = useContext(ImageContext);
  if (context === undefined) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};
