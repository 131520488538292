import React, { useContext, useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { CreatorsContext } from '../../context/creator.context';
// import Button from '../button/button.component';
import MuiButton from '@mui/material/Button';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { ProjectContext } from '../../context/project.context';
import { ProjectMessagesContext } from '../../context/messages.context';
import CreatorSelect from '../creator-select/creator-select.component';
import './assistant-top-navigation.styles.css';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import StoreIcon from '@mui/icons-material/Store';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MobileAssistantNavigation from '../navigation/assistant-navigation/mobile-assistant-navigation.component';

const AssistantTopNavigation = ({
  store = false,
  discover = false,
  chat = false,
}) => {
  const { currentCreator, setCurrentCreator } = useContext(CreatorsContext);
  const { currentProject } = useContext(ProjectContext);
  const { projectCreators, addProjectCreator, isLoading } = useContext(
    ProjectMessagesContext,
  );
  const [showCreatorSelector, setShowCreatorSelector] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!isMobile) {
      console.log('isMobile', isMobile);
    }
  }, [isMobile]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      right: '20%',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const AddCreatorButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: isMobile ? 10 : 14,
    padding: `${isMobile ? '8px 12px' : '12px 12px'}`,
    border: '1px solid',
    lineHeight: 1,
    backgroundColor: '#FFF',
    borderColor: '#C4C4C4',
    borderRadius: '23px',
    color: '#1F1F1F',
    width: `${isMobile ? '150px' : '200px'}`,
    marginRight: `${isMobile ? '15px' : '25px'}`,
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#1F1F1F',
      borderColor: '#1F1F1F',
      color: '#FFF',
      boxShadow: 'none',
    },
  });

  const StoreButton = styled(MuiButton)({
    width: '200px', 
    borderRadius: '23px', 
    fontSize: isMobile ? 10 : 14,
    lineHeight: '1', 
    padding: `${isMobile ? '8px 12px' : '12px 12px'}`, 
    backgroundColor: '#569151', 
    borderColor: '#569151', 
    color: '#FFF' 
  });

  const handleAddCreator = (creator) => {
    // if creator is already in projectCreators, don't add them again ok
    if (projectCreators.some((c) => c.id === creator.id)) {
      setShowCreatorSelector(false);
      return;
    }
    addProjectCreator(creator);
    setShowCreatorSelector(false);
  };

  const filteredCreators = projectCreators.filter(
    (creator) => creator.id !== currentCreator?.id,
  );

  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: isMobile ? 1500 : 100,
        paddingLeft: isMobile ? '0px' : '250px',
        width: '100%',
        alignItems: 'start',
        boxShadow: 'none',
        borderBottom: '1.33px solid #C4C4C4',
        backgroundColor: '#fcf8f4',
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        {chat && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
            <Grid container sx={{ width: '100%' }}>
              <Grid size={!isMobile ? 0 : 1} >
                {isMobile && <MobileAssistantNavigation />}
              </Grid>
              <Grid size={!isMobile ? 10 : 9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <AddCreatorButton onClick={() => setShowCreatorSelector(!showCreatorSelector)}>
                  Add Creator to Project
                </AddCreatorButton>
                {/* {projectCreators.length > 1 && (
                  <>
                <AvatarGroup max={isMobile ? 2 : 4}>
                  {projectCreators.map((creator, index) => (
                  <Avatar
                  sx={{ cursor: 'pointer', width: `${isMobile ? '24px' : '40px'}`, height: `${isMobile ? '24px' : '40px'}` }}
                  key={creator.id}
                  alt={creator.persona.handle}
                  src={creator.profile_image_url}
                    onClick={() => setCurrentCreator(creator)}
                    />
                  ))}
                </AvatarGroup>
                <div className="creator-count-container">
                  {projectCreators.length > 0 && projectCreators.length < 4 && (
                    <span className="creator-count">{projectCreators.length}</span>
                  )}
                </div>
                </>
                )} */}
              <div className="creator-section">
                {currentCreator && (
                <>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    sx={{marginRight: '15px'}}
                  >
                    <Avatar
                      src={currentCreator.profile_image_url}
                    alt={currentCreator.persona.handle}
                    sx={{ marginRight: '10px', width: `${isMobile ? '30px' : '40px'}`, height: `${isMobile ? '30px' : '40px'}` }}
                    />
                  </StyledBadge>
                  {!isMobile && (
                  <div className="creator-group">
                    <span className="creator-text">You're creating with</span>
                    <span className="creator-name">
                      {currentCreator.persona?.handle}
                    </span>
                  </div>
                  )}
                </>
              )}
              </div>
            </Grid>
            <Grid size={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {currentCreator && (
                <StoreButton
                variant="outlined"
                color="secondary"
                href={`https://${process.env.REACT_APP_SHOPIFY_SHOP_DOMAIN}/collections/${currentCreator.persona.handle}-collection`}
                target="_blank"
               
              >
                {' '}
                Store
              </StoreButton>
              )}
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {store && currentCreator && (
          <div className={`assistant-top-container ${store ? 'store' : ''}`}>
            <Button
              buttonType="creator_light"
              type="button"
              collapsible={true}
              navigateTo="/chat"
              onClick={() => setCurrentCreator(currentCreator)}
            >
              Design With Me
            </Button>
            <h4 className="store-title">
              {currentCreator.persona?.handle}'s Store
            </h4>
          </div>
        )} */}
        {/* {discover && (
          <div
            className={`assistant-top-container ${discover ? 'discover' : ''}`}
          >
            <Button
              buttonType="creator_light"
              type="button"
              collapsible={true}
              navigateTo={
                currentProject ? `/project/${currentProject.id}` : '/project'
              }
            >
              Back to chat
            </Button>
          </div>
        )} */}
      </Toolbar>
      <CreatorSelect
        open={showCreatorSelector}
        onClose={() => setShowCreatorSelector(false)}
        onSelect={handleAddCreator}
      />
    </AppBar>
  );
};

export default AssistantTopNavigation;
