import React, { useState, useEffect } from 'react';
import { Fragment, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNoteSticky,
  faFolderClosed,
  faShoppingCart,
  faMessage,
  faSignOut,
  faUserGear,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { CreatorsContext } from '../../../context/creator.context';
import { ProjectContext } from '../../../context/project.context';
import { useModalContext } from '../../../context/modal.context';
import { useCommandContext } from '../../../context/command.context';
import { UserContext } from '../../../context/user.context';
import { useCart } from '../../../context/cart.context';
import { getCart, createCart } from '../../../utils/api/shopify';

import { createNewProject } from '../../../utils/new-project.util';

import ProjectList from '../../project-list/project-list.component';
import FolderList from '../../folder-list/folder-list.component';
import CreatorList from '../../creators-list/creators-list.component';
import UserSettingsButton from '../../user-settings-button/user-settings-button.component';
import UserSettingsModal from '../../user-settings-modal/user-settings-modal.component';

import { folders as initialFolders } from '../../../options/folders.options';

import '../assistant-navigation/assistant-navigation.styles.css';
import MobileDrawerWrapper from '../drawer-wrapper/mobile-drawer-wrapper.component';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Divider from '@mui/material/Divider';
import ProjectDialog from '../../../routes/assistant/components/project-dialog.component';
import Box from '@mui/material/Box';
import { signOut } from '../../../utils/api/auth';
import CreatorApplyModal from '../../creator-apply/creator-apply.component';
import { toast } from 'react-toastify';
import { createCreatorProfile } from '../../../utils/api/creators';
import { useMyCreatorContext } from '../../../context/my-creator.context';
import Avatar from '@mui/material/Avatar';

const DrawerBodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
});

const ButtonWrapper = styled('div')({
  padding: '0 24px 0',
  marginBottom: '16px',
});

const ProjectListWrapper = styled('div')({
  overflow: 'auto',
  flexGrow: 1,
  padding: '0 24px 0',
});

const MobileCreatorNavigation = () => {
  const [open, setOpen] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const [folders, setFolders] = useState(initialFolders);
  const { activeModal, setActiveModal } = useModalContext();
  const { activeCommand, setActiveCommand } = useCommandContext();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [infoChange, setInfoChange] = useState(false);
  const { setCart, cart } = useCart();
  const [isCreatorApplyOpen, setIsCreatorApplyOpen] = useState(false);
  const { myCreator } = useMyCreatorContext();

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let cartData = await getCart();
        if (!cartData) {
          cartData = await createCart();
          localStorage.setItem('cartId', cartData.id);
        }
        setCart(cartData);
      } catch (error) {
        console.error('Error fetching or creating cart:', error);
        // Optionally, you can show an error message to the user here
      }
    };
    fetchCart();
  }, [setCart]);

  useEffect(() => {}, [infoChange, currentUser, setCurrentUser]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onMobileToggle = () => {
    setIsCollapsed(false);
  };

  const handleNewProject = async (projectToSend) => {
    let newProject;
    if (selectedProject) {
      // TODO: update project
      // newProject = await createNewProject(selectedProject);
    } else {
      newProject = await createNewProject(projectToSend);
    }
    if (newProject) {
      setCurrentProject(newProject);
      setProjects([...projects, newProject]);
    }
  };

  const onFoldersUpdate = (updatedFolders) => {
    setFolders(updatedFolders);
  };

  const handleNewFolder = (newFolder) => {
    setFolders([...folders, newFolder]);
  };

  const onFolderNewName = (newName, folderId) => {
    let folderToRename;

    const selectedFolder = folders.find((folder) => folder.id === folderId);

    folderToRename = { ...selectedFolder, text: newName };
    setFolders(
      folders.map((folder) =>
        folder.id === selectedFolder.id ? folderToRename : folder,
      ),
    );
  };

  const deleteFolder = (folderId) => {
    const remainingFolders = folders.filter((folder) => folder.id !== folderId);
    setFolders(remainingFolders);
  };

  const openUserSettings = () => {
    setActiveModal('userSettings');
    setActiveCommand('');
  };

  const onNewUserSettings = (updatedUser) => {
    setCurrentUser(updatedUser);
    setInfoChange(!infoChange);
  };

  const handleCreatorApplySubmit = async (creatorData) => {
    try {
      if (!currentUser) {
        toast.error('User not found');
        return;
      }
      await createCreatorProfile(creatorData);
      toast.success('Creator profile enabled successfully!');
    } catch (error) {
      console.error('Error enabling creator profile:', error);
      toast.error('Failed to enable creator profile. Please try again.');
    }
  };

  const renderDrawerBody = () => (
    <>
      <Button
        variant="text"
        component={Link}
        to="/my-creator/settings"
        fullWidth
      >
        <div className="link-button-text-wrapper">
          <FontAwesomeIcon icon={faUserGear} size="lg" />
          {isCollapsed ? '' : 'My AI persona'}
        </div>
      </Button>
      {/* <Button variant="text" href="/my-creator/styles">
          <div className="link-button-text-wrapper">
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              style={{ width: '24px', marginRight: '16px' }}
            />
            My Styles
          </div>
        </Button> */}
      <Button
        variant="text"
        component={Link}
        to="/my-creator/store"
        disabled={!myCreator || !myCreator.persona}
        fullWidth
      >
        <div
          className={`link-button-text-wrapper ${isCollapsed ? 'collapsed' : ''}`}
        >
          <FontAwesomeIcon icon={faStore} size="lg" />
          {isCollapsed ? '' : 'My Store'}
        </div>
      </Button>
    </>
  );

  const renderDrawerFooter = () => {
    const buttonStyling = {
      minWidth: '0',
    };

    if (isCollapsed) {
      buttonStyling.width = '50px';
      buttonStyling.height = '50px';
    }
    return (
      <>
        <UserSettingsButton
          user={currentUser}
          collapsed={isCollapsed}
          onClick={() => setOpen(!open)}
        />
      </>
    );
  };

  const handleCheckout = () => {
    if (cart && cart.checkoutUrl) {
      console.log('cart', cart);
      window.open(cart.checkoutUrl, '_blank');
    }
  };

  const renderPopperContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button variant="text" component={Link} to="/project">
        <FontAwesomeIcon icon={faMessage} size="lg" />
        Leave Creator Mode
      </Button>
      <Divider />
      <Button variant="text" onClick={() => signOut()}>
        <FontAwesomeIcon icon={faSignOut} size="lg" />
        Sign out
      </Button>
    </Box>
  );

  return (
    <>
      <MobileDrawerWrapper
        popperOpen={open}
        renderDrawerFooter={renderDrawerFooter}
        renderDrawerBody={renderDrawerBody}
        renderPopperContent={renderPopperContent}
        onMobileToggle={onMobileToggle}
        toggleCollapse={() => setOpen(false)}
        isCollapsed={isCollapsed}
      />
      {/* <UserSettingsModal
        user={currentUser}
        visible={activeModal === 'userSettings'}
        onSave={onNewUserSettings}
      /> */}
      <ProjectDialog
        project={selectedProject}
        open={openProjectDialog}
        onClose={() => setOpenProjectDialog(false)}
        onSubmit={handleNewProject}
      />
      <CreatorApplyModal
        isOpen={isCreatorApplyOpen}
        onClose={() => setIsCreatorApplyOpen(false)}
        onSubmit={handleCreatorApplySubmit}
      />
    </>
  );
};

export default MobileCreatorNavigation;
