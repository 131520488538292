import React from 'react';
import './popover.styles.css';

const Popover = ({ children, text, position }) => {
  return (
    <div className="popover-container">
      {children}
        <span className={`popover-text ${position}`}>{text}</span>
    </div>
  );
};

export default Popover;
