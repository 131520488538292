import React, { Fragment, useState } from 'react';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box/Box';
import { styled } from '@mui/material/styles';
import { UserContext } from '../../context/user.context';
import { deleteCreator, getCreatorLink } from '../../utils/api/creators';
import Button from '../button/button.component';
import MuiButton from '@mui/material/Button';
import MobileCreatorNavigation from '../navigation/creator-navigation/mobile-creator-navigation.component';

import './creator-top-navigation.styles.css';
import { useMyCreatorContext } from '../../context/my-creator.context';


const ENV = process.env.REACT_APP_ENV;

const CreatorTopNavigation = ({
  handleBack,
  handleDelete,
  handleSave,
  handleToggleVisibility,
}) => {
  const [isPublic, setIsPublic] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { myCreator, setMyCreator } = useMyCreatorContext();
  
  // Use Material UI's theme and breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMakePublic = (event) => {
    setIsPublic(true);
  };

  const handleMakePrivate = (event) => {
    setIsPublic(false);
  };

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDeleteCreator = async () => {
    try {
      await deleteCreator(currentUser.creator.id);
      toast.success('Creator deleted successfully');
    } catch (e) {
      console.error('Error deleting creator', e);
    }
  };

  const handleCopyLink = () => {
    if (!myCreator || !myCreator.persona) {
      return;
    }
    getCreatorLink(
      myCreator.persona.handle,
      () => {
        toast.success('Link copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy link: ', err);
        toast.error('Failed to copy link');
      },
    );
  };

  const SaveButton = styled(MuiButton)({
    borderRadius: '23px', 
    fontSize: isMobile ? 12 : 14,
    lineHeight: '1', 
    padding: `${isMobile ? '14px 25px' : '14px 40px'}`, 
    backgroundColor: '#434343', 
    borderColor: '#434343', 
    color: '#FFF' 
  });

  return (
    <Fragment>
      <div className="creator-top-navigation-container">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid size={!isMobile ? 0 : 1}  >
              <div className="creator-top-navigation-left">
                {isMobile && <MobileCreatorNavigation />}
              </div>
            </Grid>
            <Grid size={!isMobile ? 12 : 11} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div className="creator-top-navigation-right">
                {myCreator && myCreator.persona && (
                  <MuiButton
              variant="contained"
              color="secondary"
              onClick={handleCopyLink}
              sx={{ 
                marginRight: { xs: '4px', sm: '8px', md: '10px' },
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                padding: { xs: '4px 8px', sm: '6px 12px', md: '8px 16px' }
              }}
            >
                    {isMobile ? 'Copy Link' : 'Copy Your Creator Link'}
                  </MuiButton>
                )}
                {/* {ENV !== 'production' && (
                  <Button 
                    buttonType="primary" 
                    onClick={handleDeleteCreator}
                    style={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                  >
                    {isMobile ? 'Delete' : 'Delete Creator'}
                  </Button>
                )} */}
          {/* <Button
            buttonType={isPublic ? 'creator_private' : 'creator_public'}
            onClick={isPublic ? handleMakePrivate : handleMakePublic}
            style={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
          >
            {isPublic ? 'Private' : 'Public'}
          </Button> */}
          <SaveButton 
            onClick={handleSave}
          >
            Save
          </SaveButton>
          </div>
        </Grid>
      </Grid>
      </Box>
      </div>
    </Fragment>
  );
};

export default CreatorTopNavigation;
