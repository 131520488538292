import React, { useState, useEffect, useContext } from 'react';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faArrowsRotate,
  faCopy,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useCart } from '../../context/cart.context';
import { useImage } from '../../context/image.context';

import {
  createCart,
  addToCart,
  getProductVariantByHandleSku,
} from '../../utils/api/shopify';
import { addProductToStore } from '../../utils/api/creators';
import { getImageOrientation } from '../../utils/image-orientation.util';
import { performProductSearch } from '../../utils/google-vision.util';
import { shopImage } from '../../utils/api/shop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ProductSearchGrid from '../product-search-grid/product-search-grid.component';
import Popover from '../popover/popover.component';

import './image-modal.styles.css';
import MiniSpinner from '../mini-spinner/mini-spinner.component';

const ImageModal = ({
  imageUrl,
  initialIndex,
  imageArray,
  handleVariation, prompt,
}) => {
  const { addItemToCart, isVariantInCart, removeItemFromCart } = useCart();
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
  const [imageClass, setImageClass] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const [products, setProducts] = useState([]);
  const imageRef = useRef(null);
  const [cart, setCart] = useState(null);
  const { uploadedImage, handleImageUpload, clearUploadedImage } = useImage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);

  const openModal = async () => {
    setIsOpen(true);
    try {
      console.log('shopping image', imageUrl);
      setProductsLoading(true);
      console.log('productsLoading', productsLoading);
      const shoppableProductIds = await shopImage(imageUrl);

      const shopify_products = [];
      for (const productId of shoppableProductIds) {
        const shopify_product = await getProductVariantByHandleSku(
          productId.handle,
          productId.sku,
        );
        if (shopify_product) {
          shopify_products.push(shopify_product);
        }
      }

      setProducts(shopify_products);
      console.log('productsLoading', productsLoading);
      setProductsLoading(false);
    } catch (error) {
      console.error('Error performing product search:', error);
      toast.error('Failed to load some products');
      setProductsLoading(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    const orientationClass = getImageOrientation(img.width, img.height);
    setImageClass(orientationClass);
    if (imageRef.current) {
      setImageWidth(imageRef.current.clientWidth);
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length,
    );
  };

  const handleKeyDown = (event) => {
    if (!isOpen) return;
    if (event.key === 'ArrowRight' || event.key === ' ') {
      handleNextImage();
    } else if (event.key === 'ArrowLeft') {
      handlePreviousImage();
    }
  };

  useEffect(() => {
    if (imageWidth > 0) {
      document.documentElement.style.setProperty(
        '--image-width',
        `${imageWidth}px`,
      );
    }
  }, [imageWidth]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    initializeCart();
  }, []);

  const initializeCart = async () => {
    try {
      const newCart = await createCart();
      setCart(newCart);
    } catch (error) {
      console.error('Error creating cart:', error);
      toast.error('Failed to initialize cart. Please try again.');
    }
  };

  const handleAddToCart = async (variantId) => {
    try {
      console.log('variantId', variantId);
      await addItemToCart(variantId);
      toast.success('Product added to cart successfully!');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add product to cart. Please try again.');
    }
  };

  const handleRemoveFromCart = async (variantId) => {
    try {
      await removeItemFromCart(variantId);
      toast.success('Product removed from cart successfully!');
    } catch (error) {
      console.error('Error removing from cart:', error);
      toast.error('Failed to remove product from cart. Please try again.');
    }
  };

  const renderActionButton = (variant) => {
    const variantId = variant.id;

    const isInCart = isVariantInCart(variantId);

    return (
      <button
        className={`buy-now ${isInCart ? 'in-cart' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          isInCart
            ? handleRemoveFromCart(variantId)
            : handleAddToCart(variantId);
        }}
      >
        {isInCart ? 'Remove' : 'Add to Cart'}
      </button>
    );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCopyPrompt = () => {
    navigator.clipboard.writeText(`/design ${prompt}`);
    setOpenSnackbar(true);
  };

  const SkeletonGrid = () => {
    const itemsPerRow = isMobile 
      ? 2 
      : isSmallerScreen 
        ? 2 
        : 2;
    const rows = 3;
    
    console.log('Screen conditions:', { isMobile, isSmallerScreen, rows });
    
    return Array(rows).fill(null).map((_, rowIndex) => (
      <Grid 
        key={`skeleton-row-${rowIndex}`} 
        sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          gap: 2, 
          pt: 2 
        }}
      >
        {Array(itemsPerRow).fill(null).map((_, itemIndex) => (
          <Skeleton
            key={`skeleton-${rowIndex}-${itemIndex}`}
            animation="wave"
            variant="rectangular"
            width={160}
            height={280}
          />
        ))}
      </Grid>
    ));
  };

  return (
    <div className="image-modal">
      <div className="thumbnail-container">
        <img
          src={imageUrl}
          alt="Thumbnail"
          onClick={openModal}
          className="thumbnail"
        />
      </div>
      <div className="image-modal-button-container">
        <button className="copy-prompt-button" onClick={handleCopyPrompt}>
          {!isMobile && <FontAwesomeIcon icon={faCopy} />} Copy Prompt
        </button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1500}
          onClose={handleSnackbarClose}
          message="Prompt copied to clipboard"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={{
            '& .MuiPaper-root': {
              // minWidth: 'fit-content',
              fontSize: '12px',
              textAlign: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: 'black',
              boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
            }
          }}
        />
        <button 
          className="variation-button" 
          onClick={(e) => {
            e.stopPropagation();
            const newMessage = {
              text: '/variation A variation of the image',
              variationImageUrl: imageUrl,
            };
            handleImageUpload(imageUrl);
            handleVariation(newMessage);
          }}
        >
          {!isMobile && <FontAwesomeIcon icon={faArrowsRotate} />} Variation
        </button>
        <button className="shop-image" onClick={openModal}>
          Shop Image
        </button>
      </div>
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <FontAwesomeIcon
            icon={faXmark}
            className="close-icon"
            onClick={closeModal}
          />
          <Box sx={{ 
            width: '100%', 
            height: isMobile ? '100%' : '90%',
            display: 'flex',
            alignItems: isMobile ? 'start' : 'center',
            justifyContent: 'center',
            padding: 2,
            position: isMobile ? 'relative' : 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: isMobile ? 'auto' : 'visible'
          }}>
            <Grid 
              container 
              spacing={!isMobile ? 2 : 0}
              direction={isMobile ? 'column' : 'row'}
              sx={{
                maxWidth: '1200px',
                height: 'auto',
                margin: '0 auto',
                position: 'relative'
              }}
            >
              <Grid
                size={!isMobile ? (!isSmallerScreen ? 8 : 8) : 12}
                sx={{ paddingRight: 0, marginTop: !isMobile && productsLoading ? '60px' : isMobile ? '60px' : '0px' }}
              >
                <div
                  className="image-modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="chevron left-chevron"
                    onClick={handlePreviousImage}
                  /> */}
                  <img
                    src={imageArray[currentImageIndex]}
                    alt="Full Size"
                    className={`modal-image ${imageClass}`}
                    onLoad={handleImageLoad}
                    ref={imageRef}
                  />
                  {/* <FontAwesomeIcon
                    icon={faChevronRight}
                    className="chevron right-chevron"
                    onClick={handleNextImage}
                  /> */}
                </div>
              </Grid>
              <Grid size={!isMobile ? (!isSmallerScreen ? 4 : 4) : 12}>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    overflowY: 'auto',
                    backgroundColor: 'none',
                    paddingY: !isMobile ? 2 : 1,
                    paddingX: 2,
                  }}
                >
                  {productsLoading ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', overflowY: 'auto', marginTop: !isMobile ? '10px' : '0px', height: '100%' }}>
                        <SkeletonGrid />
                      </Box>
                  ) : (
                    <ProductSearchGrid
                      products={products}
                      renderActionButton={renderActionButton}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
