import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Outlet } from 'react-router-dom';
import theme from '../../../theme';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

const s3_url = process.env.REACT_APP_S3_URL;
const logo_white_url = s3_url + '/brand/logo_white.svg';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '50px',
  padding: theme.spacing(2, 2),
  justifyContent: 'space-between',
  flexGrow: 0,
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2),
  justifyContent: 'center',
  marginTop: 'auto',
  position: 'relative',
  flexGrow: 0,
}));

const DrawerBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
}));

const CollapseButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 5px',
  backgroundColor: 'transparent',
  position: 'absolute',
  top: 0,
  left: '100%',
  bottom: 0,
}));

export default function MobileDrawerWrapper({
  isCollapsed,
  popperOpen,
  renderDrawerFooter,
  renderDrawerBody,
  renderPopperContent,
  renderDrawerHeader,
  onMobileToggle,
  toggleCollapse,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  }, [anchorRef.current]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popperOpen && 
        anchorRef.current && 
        !anchorRef.current.contains(event.target) &&
        !event.target.closest('.MuiPopper-root')
      ) {
        toggleCollapse();
      }
    }

    if (popperOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperOpen, toggleCollapse]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    console.log('Toggle clicked');
    console.log('Current state - isMobile:', isMobile, 'mobileOpen:', mobileOpen, 'isClosing:', isClosing);
    
    if (!isClosing) {
      const newState = !mobileOpen;
      console.log('Setting mobileOpen to:', newState);
      setMobileOpen(newState);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: 'fixed',
            top: '8px',
            left: '20px',
            zIndex: 2000,
            color: 'black',
            backgroundColor: 'transparent',
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      
      <MuiDrawer
        open={isMobile ? mobileOpen : !isCollapsed}
        onClose={isMobile ? handleDrawerClose : undefined}
        onTransitionEnd={handleDrawerTransitionEnd}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: isMobile ? drawerWidth : (isCollapsed ? 90 : drawerWidth),
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          '& .MuiDrawer-paper': {
            width: isMobile ? drawerWidth : (isCollapsed ? 90 : drawerWidth),
            boxSizing: 'border-box',
            backgroundColor: theme.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || 'black',
            borderRight: 'none',
            overflow: 'visible',
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          '& .MuiAvatar-root': {
            position: 'absolute',
            left: '8px',
            width: '32px',
            height: '32px',
          },
          '& .MuiButton-outlined': {
            backgroundColor: theme.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || 'black',
            padding: '12px',
            borderRadius: '32px',
            borderColor: 'gray',
            minWidth: '0px',
          },
          '& .MuiButton-text': {
            padding: '16px 0px',
          },
          '& .link-button-text-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'flex-start',
            width: '140px',
          },
          '& .svg-inline--fa': {
            marginRight: isCollapsed ? '0px' : '16px',
            color: 'white',
          },
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        <DrawerHeader>
          <Link style={{ padding: '16px' }} to="/project">
            <img src={logo_white_url} alt="Logo" />
          </Link>
          {renderDrawerHeader && renderDrawerHeader()}
        </DrawerHeader>
        <DrawerBody>{renderDrawerBody()}</DrawerBody>
        <DrawerFooter ref={anchorRef}>
          {renderDrawerFooter()}
        </DrawerFooter>
        {!isMobile && (
          <CollapseButtonContainer>
            <button
              className="collapse-button"
              onClick={toggleCollapse}
            ></button>
          </CollapseButtonContainer>
        )}
      </MuiDrawer>

      {anchorEl && (
        <Popper
          sx={{
            '& .MuiPaper-root': {
              color: 'white',
              background: theme.components?.MuiDrawer?.styleOverrides?.paper?.backgroundColor || 'black',
              margin: '16px -10px',
              zIndex: 1300,
            },
          }}
          open={popperOpen}
          anchorEl={anchorEl}
          placement={'right-end'}
          transition
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Paper
              sx={{
                padding: '8px',
                borderRadius: '16px',
                '& .MuiDivider-root': {
                  borderColor: 'gray',
                },
                '& .MuiButton-root': {
                  padding: '20px',
                },
                '& .svg-inline--fa': {
                  marginRight: '16px',
                },
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Fade {...TransitionProps} timeout={150}>
                {renderPopperContent()}
              </Fade>
            </Paper>
          )}
        </Popper>
      )}

      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          width: '100%',
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Grid container flexGrow={1} style={{ position: 'relative' }}>
          <Outlet />
        </Grid>
      </Box>
    </Box>
  );
}
