import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faCartShopping,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const ProductSearchCardMui = ( {product, store = false, renderActionButton} ) => {

    const formatPrice = (price) => {
        if (!price) return 'N/A';
        const { amount, currencyCode } = price;
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount);
      };
    
      const getImage = (images) => {
        if (!images || !images.edges) return '';
    
        return images.edges[0].node?.url || '';
      };
    
      const getImageAlt = (images) => {
        if (!images || !images.edges) return '';
    
        return images.edges[0].node?.altText || '';
      };

      const CardStyles = {
        maxWidth: 200,
        marginTop: 1,
        height: 280,
        backgroundColor: 'white',
        borderRadius: 2
      }

    return (
        <Card sx={CardStyles}>
            <CardMedia
                component="img"
                sx={{ height: 120, padding: 1, objectFit: 'contain' }}
                image={product.image ? product.image.url : getImage(product.product.images)}
                title={product.image
                    ? product.image.altText
                    : getImageAlt(product.product.images)}
            />
            <CardContent>
                <div className="product-search-card-vendor">
                    {product.product.vendor || ''}
                </div>
                <div className="product-search-card-title">
                    {product.product.title}
                </div>
                <div className="price-label">
                    <FontAwesomeIcon icon={faTag} /> {formatPrice(product.price)}
                </div>
                {/* {renderActionButton(product)} */}
            </CardContent>
            <CardActions>
                {renderActionButton(product)}
            </CardActions>
        </Card> 
    )
}

export default ProductSearchCardMui;