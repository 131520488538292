import React from 'react';
import SignUpForm from '../../components/sign-up-form/sign-up-form.component';

import './authenticate.styles.css';

const SignUp = () => {
  return (
    <div className="authenticate-container">
      <SignUpForm />
    </div>
  );
};

export default SignUp;
