import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/user.context';
import { signOut } from '../../../utils/api/auth';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { HiMenu, HiX } from 'react-icons/hi';
import Button from '../../button/button.component';
import MobileMenu from '../../mobile-menu/mobile-menu.component';

import './home-navigation.styles.css';

const S3_URL = process.env.REACT_APP_S3_URL;
const LOGO_BLACK_URL = S3_URL + '/brand/goodhues_logo_black.png';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);
  
  const [selectedTab, setSelectedTab] = useState('explorers');
  const [hoverPosition, setHoverPosition] = useState({ left: 0, width: 0 });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const isAuthPage = location.pathname === '/sign-in' || location.pathname === '/sign-up';

  useEffect(() => {
    const selectedElement = menuRef.current?.querySelector('.selected');
    if (selectedElement) {
      const { offsetLeft, offsetWidth } = selectedElement;
      setHoverPosition({ left: offsetLeft, width: offsetWidth });
    }
  }, [selectedTab]);

  const handleButtonClick = (section) => {
    setSelectedTab(section);
    navigate(section === 'explorers' ? '/' : '/creators');
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  return (
    <Fragment>
      <div className="home-navigation-container">
        <Link
          className={`logo-container ${isAuthPage ? 'adjusted' : ''}`}
          to="/"
        >
          <img src={LOGO_BLACK_URL} className="main-logo" alt="Goodhues Logo" />
        </Link>
        
        {!isAuthPage && (
          <>
            <div className="desktop-menu">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                <Grid container sx={{width: '100%'}}>
                  <Grid size={8} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div className="main-sections" ref={menuRef}>
                      <div
                        className={`main-section-button ${selectedTab === 'explorers' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('explorers')}
                      >
                        Home
                      </div>
                      <div
                        className={`main-section-button ${selectedTab === 'creators' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('creators')}
                      >
                        Creators
                      </div>
                      <div
                        className="main-section-button-hover-background"
                        style={{
                          left: `${hoverPosition.left}px`,
                          width: `${hoverPosition.width}px`,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid size={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    <div className="login-signup-container">
                      {!currentUser ? (
                        <div className="logged-out">
                          <Button
                            buttonType="login"
                            type="button"
                            navigateTo="/sign-in"
                          >
                            Log in
                          </Button>
                          <Button
                            buttonType="signup"
                            type="button"
                            navigateTo="/sign-up"
                          >
                            Sign up
                          </Button>
                        </div>
                      ) : (
                        <div className="logged-in">
                          <Button
                            buttonType="login"
                            type="button"
                            navigateTo="/assistant"
                          >
                            Assistant
                          </Button>
                          <span className="sign-out-link" onClick={signOut}>
                            Sign Out
                          </span>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>

            <button 
              className="hamburger-button"
              onClick={toggleMobileMenu}
              aria-label="Toggle menu"
            >
              {isMobileMenuOpen ? <HiX size={24} /> : <HiMenu size={24} />}
            </button>

            <MobileMenu 
              isOpen={isMobileMenuOpen}
              selectedTab={selectedTab}
              handleButtonClick={handleButtonClick}
              currentUser={currentUser}
              signOut={signOut}
            />
          </>
        )}
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
