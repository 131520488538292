import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMessage,
  faSignOut,
  faStore,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { useMyCreatorContext } from '../../../context/my-creator.context';
import { UserContext } from '../../../context/user.context';
import DrawerWrapper from '../drawer-wrapper/drawer-wrapper.component';
import UserSettingsButton from '../../user-settings-button/user-settings-button.component';
import { signOut } from '../../../utils/api/auth';

export default function CreatorNavigation() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { myCreator } = useMyCreatorContext();

  const renderPopperContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button variant="text" component={Link} to="/project">
        <FontAwesomeIcon icon={faMessage} size="lg" />
        Leave Creator Mode
      </Button>
      <Divider />
      <Button variant="text" onClick={() => signOut()}>
        <FontAwesomeIcon icon={faSignOut} size="lg" />
        Sign out
      </Button>
    </Box>
  );

  const renderDrawerBody = () => (
    <>
      <Button
        variant="text"
        component={Link}
        to="/my-creator/settings"
        fullWidth
      >
        <div className="link-button-text-wrapper">
          <FontAwesomeIcon icon={faUserGear} size="lg" />
          {isCollapsed ? '' : 'My AI persona'}
        </div>
      </Button>
      {/* <Button variant="text" href="/my-creator/styles">
          <div className="link-button-text-wrapper">
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              style={{ width: '24px', marginRight: '16px' }}
            />
            My Styles
          </div>
        </Button> */}
      <Button
        variant="text"
        component={Link}
        to="/my-creator/store"
        disabled={!myCreator || !myCreator.persona}
        fullWidth
      >
        <div
          className={`link-button-text-wrapper ${isCollapsed ? 'collapsed' : ''}`}
        >
          <FontAwesomeIcon icon={faStore} size="lg" />
          {isCollapsed ? '' : 'My Store'}
        </div>
      </Button>
    </>
  );

  const renderDrawerFooter = () => {
    const buttonStyling = {
      minWidth: '0',
    };

    if (isCollapsed) {
      buttonStyling.width = '50px';
      buttonStyling.height = '50px';
    }
    return (
      <>
        <UserSettingsButton
          user={currentUser}
          collapsed={isCollapsed}
          onClick={() => setOpen(!open)}
        />
      </>
    );
  };

  return (
    <DrawerWrapper
      isCollapsed={isCollapsed}
      toggleCollapse={() => setIsCollapsed(!isCollapsed)}
      popperOpen={open}
      renderDrawerFooter={renderDrawerFooter}
      renderDrawerBody={renderDrawerBody}
      renderPopperContent={renderPopperContent}
    />
  );
}
{
  /* <Box sx={{ display: 'flex' }}>
        <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiButton-outlined': {
            backgroundColor:
              theme.components?.MuiDrawer?.styleOverrides?.paper || 'black',
            padding: '8px',
            borderRadius: '32px',
            borderColor: 'gray',
          },
          '& .MuiButton-text': {
            padding: '16px 0px',
          },
          '& .link-button-text-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '140px',
          },
          '& .svg-inline--fa': {
            marginRight: '16px',
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
      >
        <Popper
          sx={{
            '& .MuiPaper-root': {
              color: 'white',
              background:
                theme.components?.MuiDrawer?.styleOverrides?.paper
                  ?.backgroundColor || 'black',
              margin: '16px',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          placement={'right-end'}
          transition
        >
          {({ TransitionProps }) => (
            <Paper
              sx={{
                padding: '8px',
                borderRadius: '16px',
                '& .MuiDivider-root': {
                  borderColor: 'gray',
                },
                '& .MuiButton-root': {
                  padding: '20px',
                },
                '& .svg-inline--fa': {
                  marginRight: '16px',
                },
              }}
            >
              <Fade {...TransitionProps} timeout={150}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Button variant="text" component={Link} to="/discover">
                    <FontAwesomeIcon icon={faMessage} size="lg" />
                    Leave Creator Mode
                  </Button>
                  <Divider />
                  <Button variant="text" onClick={() => signOut()}>
                    <FontAwesomeIcon icon={faSignOut} size="lg" />
                    Sign out
                  </Button>
                </Box>
              </Fade>
            </Paper>
          )}
        </Popper>
        <DrawerHeader>
          <Link style={{ padding: '16px' }} to="/discover">
            <img src={logo_white} />
          </Link>
        </DrawerHeader>
        <Button variant="text" component={Link} to="/my-creator/settings">
          <div className="link-button-text-wrapper">
            <FontAwesomeIcon icon={faUserGear} size="lg" />
            My AI persona
          </div>
        </Button>
        {/* <Button variant="text" href="/my-creator/styles">
          <div className="link-button-text-wrapper">
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              style={{ width: '24px', marginRight: '16px' }}
            />
            My Styles
          </div>
        </Button> */
}
//       <Button
//         variant="text"
//         component={Link}
//         to="/my-creator/store"
//         disabled={!myCreator || !myCreator.persona}
//       >
//         <div className="link-button-text-wrapper">
//           <FontAwesomeIcon icon={faStore} size="lg" />
//           My Store
//         </div>
//       </Button>
//       <DrawerFooter ref={anchorRef}>
//         <Button variant="outlined">
//           <div
//             className="link-button-text-wrapper"
//             onClick={() => setOpen(!open)}
//           >
//             <Avatar
//               src={myCreator?.profile_image_url}
//               sx={{ marginRight: '16px', width: '32px', height: '32px' }}
//             />
//             My Account
//           </div>
//         </Button>
//       </DrawerFooter>
//     </Drawer>
//     <Outlet />
//   </Box>
// ); */}

