import React, { useState, useEffect } from 'react';
import { Fragment, useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNoteSticky,
  faFolderClosed,
  faShoppingCart,
  faMessage,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { CreatorsContext } from '../../../context/creator.context';
import { ProjectContext } from '../../../context/project.context';
import { useModalContext } from '../../../context/modal.context';
import { useCommandContext } from '../../../context/command.context';
import { UserContext } from '../../../context/user.context';
import { useCart } from '../../../context/cart.context';
import { getCart, createCart } from '../../../utils/api/shopify';

import { createNewProject } from '../../../utils/new-project.util';

import ProjectList from '../../project-list/project-list.component';
import FolderList from '../../folder-list/folder-list.component';
import CreatorList from '../../creators-list/creators-list.component';
import UserSettingsButton from '../../user-settings-button/user-settings-button.component';
import UserSettingsModal from '../../user-settings-modal/user-settings-modal.component';

import { folders as initialFolders } from '../../../options/folders.options';

import './assistant-navigation.styles.css';
import MobileDrawerWrapper from '../drawer-wrapper/mobile-drawer-wrapper.component';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import Divider from '@mui/material/Divider';
import ProjectDialog from '../../../routes/assistant/components/project-dialog.component';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import { signOut } from '../../../utils/api/auth';
import CreatorApplyModal from '../../creator-apply/creator-apply.component';
import { toast } from 'react-toastify';
import { createCreatorProfile } from '../../../utils/api/creators';
import { ProjectMessagesContext } from '../../../context/messages.context';

const DrawerBodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
});

const ButtonWrapper = styled('div')({
  padding: '0 24px 0',
  marginBottom: '16px',
});

const ProjectListWrapper = styled('div')({
  overflow: 'auto',
  flexGrow: 1,
  padding: '0 24px 0',
});

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#1f1f1f',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '14px',
    boxShadow: 'none',
    height: '50px',
    width: '100%',
    position: 'relative',
    '& .MuiAvatar-root': {
      position: 'static',
      width: '30px !important',
      height: '30px !important',
    //   flexShrink: 0,
    },
    '& .creator-content': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      width: '100%',
      padding: '0 20px',
      position: 'relative' 
    },
    '& .creator-text': {
      flex: 1,
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginLeft: 0
    }
  }));
  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      right: '10%',
      bottom: '10%',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const MobileAssistantNavigation = () => {
  const [open, setOpen] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const [folders, setFolders] = useState(initialFolders);
  const { activeModal, setActiveModal } = useModalContext();
  const { activeCommand, setActiveCommand } = useCommandContext();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [infoChange, setInfoChange] = useState(false);
  const { setCart, cart } = useCart();
  const [isCreatorApplyOpen, setIsCreatorApplyOpen] = useState(false);
  const { currentCreator, setCurrentCreator } = useContext(CreatorsContext);
  const { projectCreators, addProjectCreator, isLoading } = useContext(
    ProjectMessagesContext,
  );

  useEffect(() => {
    const fetchCart = async () => {
      try {
        let cartData = await getCart();
        if (!cartData) {
          cartData = await createCart();
          localStorage.setItem('cartId', cartData.id);
        }
        setCart(cartData);
      } catch (error) {
        console.error('Error fetching or creating cart:', error);
        // Optionally, you can show an error message to the user here
      }
    };
    fetchCart();
  }, [setCart]);

  useEffect(() => {}, [infoChange, currentUser, setCurrentUser]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onMobileToggle = () => {
    setIsCollapsed(false);
  };

  const handleNewProject = async (projectToSend) => {
    let newProject;
    if (selectedProject) {
      // TODO: update project
      // newProject = await createNewProject(selectedProject);
    } else {
      newProject = await createNewProject(projectToSend);
    }
    if (newProject) {
      setCurrentProject(newProject);
      setProjects([...projects, newProject]);
    }
  };

  const onFoldersUpdate = (updatedFolders) => {
    setFolders(updatedFolders);
  };

  const handleNewFolder = (newFolder) => {
    setFolders([...folders, newFolder]);
  };

  const onFolderNewName = (newName, folderId) => {
    let folderToRename;

    const selectedFolder = folders.find((folder) => folder.id === folderId);

    folderToRename = { ...selectedFolder, text: newName };
    setFolders(
      folders.map((folder) =>
        folder.id === selectedFolder.id ? folderToRename : folder,
      ),
    );
  };

  const deleteFolder = (folderId) => {
    const remainingFolders = folders.filter((folder) => folder.id !== folderId);
    setFolders(remainingFolders);
  };

  const openUserSettings = () => {
    setActiveModal('userSettings');
    setActiveCommand('');
  };

  const onNewUserSettings = (updatedUser) => {
    setCurrentUser(updatedUser);
    setInfoChange(!infoChange);
  };

  const handleCreatorApplySubmit = async (creatorData) => {
    try {
      if (!currentUser) {
        toast.error('User not found');
        return;
      }
      await createCreatorProfile(creatorData);
      toast.success('Creator profile enabled successfully!');
    } catch (error) {
      console.error('Error enabling creator profile:', error);
      toast.error('Failed to enable creator profile. Please try again.');
    }
  };

  const renderDrawerBody = () => (
    <DrawerBodyWrapper>
      <ButtonWrapper>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => setOpenProjectDialog(true)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
          {isCollapsed ? '' : 'New Project'}
        </Button>
      </ButtonWrapper>
      {!isCollapsed && (
        <ProjectListWrapper>
          <ProjectList
            title="Today"
            icon={faNoteSticky}
            open={true}
            folders={folders}
            onAddToFolder={onFoldersUpdate}
            onNewFolder={handleNewFolder}
          />
          <ProjectList
            title="Previous"
            icon={faNoteSticky}
            previous={true}
            open={false}
            folders={folders}
            onAddToFolder={onFoldersUpdate}
            onNewFolder={handleNewFolder}
          />
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ color: '#7b7b7b', fontSize: '12px', fontWeight: '600', marginLeft: '20px', marginBottom: '20px', marginTop: '20px' }}>
              Project Creators
            </Typography>
            <Stack spacing={1}>
              {projectCreators.map((creator, index) => (
                <Item
                  key={index}
                  onClick={() => setCurrentCreator(creator)}
                >
                  <div className="creator-content">
                    {creator.id === currentCreator?.id && (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        sx={{marginRight: '12px'}}
                  >
                        <Avatar
                          alt={creator.persona.handle}
                          src={creator.profile_image_url}
                        />
                      </StyledBadge>
                    )}
                    {creator.id !== currentCreator?.id && (
                      <Avatar
                        alt={creator.persona.handle}
                        src={creator.profile_image_url}
                      />
                    )}
                    <span className="creator-text" style={{marginLeft: creator.id === currentCreator?.id ? '-10px' : '5px'}}>
                      {creator.persona.handle}
                    </span>
                  </div>
                </Item>
              ))}
            </Stack>
          </Box>
          {/* <FolderList
            title="Folders"
            links={folders}
            icon={faFolderClosed}
            open={false}
            onFolderNameChange={onFolderNewName}
            onFolderDelete={deleteFolder}
          /> */}
        </ProjectListWrapper>
      )}
    </DrawerBodyWrapper>
  );

  const renderDrawerFooter = () => (
    <UserSettingsButton
      user={currentUser}
      collapsed={isCollapsed}
      onClick={() => setOpen(!open)}
    />
  );

  const renderDrawerHeader = () => {
    const cartItemCount = cart?.lines?.edges?.length || 0;
    return (
      <>
        <Button
          sx={{
            '& .svg-inline--fa': { color: 'white', margin: 0 },
            '&.MuiButton-text.Mui-disabled .svg-inline--fa': {
              color: 'rgba(255, 255, 255, 0.5)',
            },
          }}
          variant="text"
          onClick={handleCheckout}
          disabled={cartItemCount === 0}
        >
          <FontAwesomeIcon icon={faShoppingCart} size="lg" />
          {cartItemCount > 0 && (
            <span className="cart-count">{cartItemCount}</span>
          )}
        </Button>
      </>
    );
  };

  const handleCheckout = () => {
    if (cart && cart.checkoutUrl) {
      console.log('cart', cart);
      window.open(cart.checkoutUrl, '_blank');
    }
  };

  const renderPopperContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {currentUser && !currentUser.creator ? (
        <Button variant="text" onClick={() => setIsCreatorApplyOpen(true)}>
          <FontAwesomeIcon icon={faMessage} size="lg" />
          Apply to be a creator
        </Button>
      ) : (
        <Button variant="text" component={Link} to="/my-creator/settings">
          Creator Dashboard
        </Button>
      )}
      <Divider />
      <Button variant="text" onClick={() => signOut()}>
        <FontAwesomeIcon icon={faSignOut} size="lg" />
        Sign out
      </Button>
    </Box>
  );

  return (
    <>
      <MobileDrawerWrapper
        popperOpen={open}
        renderDrawerHeader={renderDrawerHeader}
        renderDrawerFooter={renderDrawerFooter}
        renderDrawerBody={renderDrawerBody}
        renderPopperContent={renderPopperContent}
        onMobileToggle={onMobileToggle}
        toggleCollapse={() => setOpen(false)}
        isCollapsed={isCollapsed}
      />
      {/* <UserSettingsModal
        user={currentUser}
        visible={activeModal === 'userSettings'}
        onSave={onNewUserSettings}
      /> */}
      <ProjectDialog
        project={selectedProject}
        open={openProjectDialog}
        onClose={() => setOpenProjectDialog(false)}
        onSubmit={handleNewProject}
      />
      <CreatorApplyModal
        isOpen={isCreatorApplyOpen}
        onClose={() => setIsCreatorApplyOpen(false)}
        onSubmit={handleCreatorApplySubmit}
      />
    </>
  );
};

export default MobileAssistantNavigation;
