import React from 'react';
import { useNavigate } from 'react-router-dom';
import './mobile-menu.styles.css';

const MobileMenu = ({ 
  isOpen, 
  selectedTab, 
  handleButtonClick, 
  currentUser, 
  signOut 
}) => {
  const navigate = useNavigate();
  
  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      <div className="mobile-menu-content">
        <div className="mobile-main-sections">
          <div
            className={`mobile-section-button ${selectedTab === 'explorers' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('explorers')}
          >
            Home
          </div>
          <div
            className={`mobile-section-button ${selectedTab === 'creators' ? 'selected' : ''}`}
            onClick={() => handleButtonClick('creators')}
          >
            Creators
          </div>
        </div>
        <div className="mobile-auth-section">
          {!currentUser ? (
            <>
              <div className="mobile-section-button" onClick={() => navigate('/sign-in')}>
                Log in
              </div>
              <div className="mobile-section-button" onClick={() => navigate('/sign-up')}>
                Sign up
              </div>
            </>
          ) : (
            <>
              <div className="mobile-section-button" onClick={() => navigate('/assistant')}>
                Assistant
              </div>
              <span className="mobile-sign-out-link" onClick={signOut}>
                Sign Out
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;