import React, { useState, useEffect, useCallback, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ProjectMessagesContext } from '../../context/messages.context';
import { getCreators } from '../../utils/api/creators';
import { debounce } from '../../utils/general';
import { FixedSizeList } from 'react-window';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CreatorSelect({ open, onClose, onSelect }) {
  const [search, setSearch] = useState('');
  const [availableCreators, setAvailableCreators] = useState([]);
  const [openList, setOpenList] = useState(false);
  const { projectCreators } = useContext(ProjectMessagesContext);

  useEffect(() => {
    if (open) {
      getAvailableCreators();
    }
  }, [open]);

  const getAvailableCreators = async () => {
    const { creators } = await getCreators(search);

    // remove project creators from available creators
    const projectCreatorsIds = projectCreators.map((creator) => creator.id);
    const uniqueCreators = creators.filter(
      (creator) => !projectCreatorsIds.includes(creator.id),
    );
    // limit to 10 creators
    const newAvailableCreators = uniqueCreators.slice(0, 10);
    setAvailableCreators(newAvailableCreators);
  };

  const debouncedFetchCreators = useCallback(
    debounce(getAvailableCreators, 500),
    [],
  );

  const handleSearch = (value) => {
    setSearch(value);
    debouncedFetchCreators(value);
  };

  const handleExpand = () => {
    setOpenList(!openList);
  };

  const renderCreator = ({ index, style }) => {
    const creator = availableCreators[index];
    return (
      <ListItemButton style={style} onClick={() => onSelect(creator)}>
        <ListItemAvatar>
          <Avatar src={creator.profile_image_url} />
        </ListItemAvatar>
        <ListItemText primary={creator.persona.handle} />
      </ListItemButton>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select Creators for this Project</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
      </IconButton>
      <DialogContent>      
        <TextField
          autoFocus
          margin="dense"
          label="Search Creators"
          type="search"
          fullWidth
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#1F1F1F',
              },
            },
            '& label.Mui-focused': { 
              color: '#1F1F1F',
            },
          }}
        />
        <Box sx={{ height: 200, width: '100%', marginTop: 2 }}>
          {availableCreators.length > 0 ? (
            <FixedSizeList
              height={200}
            width="100%"
            itemSize={56} // Height of each ListItemButton
            itemCount={availableCreators.length}
            overscanCount={5}
          >
            {renderCreator}
            </FixedSizeList>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant="body1">All available creators have been added to this project.</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
