import { fetchProtectedRoute } from './utils';

export const shopImage = async (imageUrl) => {
  const response = await fetchProtectedRoute(
    '/shop/search/image',
    'POST',
    null,
    {
      imageUrl,
    },
  );
  return response;
  // return [
  //   {
  //     handle:
  //       'piped-soft-cozy-sateen-pillowcase-set-homebird-textiles-inc-1417858890',
  //     sku: '1417858890',
  //   },
  // ];
};
